<template>
  <section>
    <Header />
    <section class="home flex-grow-1 d-flex flex-column flex-fill">
      <HeroHeader :data="header" />
      <div class="container">

        <div class="row py-4 pt-5">
          <div class="col-md-3">
            <div
              class="nav flex-column nav-pills"
              id="v-pills-tab-admin"
              role="tablist"
              aria-orientation="vertical"
            >
              <router-link
                :to="{ name: 'Inscricoes' }"
                class="nav-link"
                data-toggle="pill"
              >
                <feather
                  class="color-tertiary is-16 mr-1"
                  type="list"
                ></feather>
                Inscrições</router-link
              >
              <router-link
                :to="{ name: 'Clientes' }"
                class="nav-link"
                data-toggle="pill"
              >
                <feather
                  class="color-tertiary is-16 mr-2"
                  type="user"
                ></feather>
                Clientes</router-link
              >
              <!-- <router-link
                :to="{ name: 'Pedidos' }"
                class="nav-link"
                data-toggle="pill"
              >
                <feather
                  class="color-tertiary is-16 mr-2"
                  type="shopping-cart"
                ></feather
                >Pedidos</router-link
              > -->

              <router-link
                :to="{ name: 'Admins' }"
                class="nav-link"
                data-toggle="pill"
              >
                <feather
                  class="color-tertiary is-16 mr-2"
                  type="settings"
                ></feather
                >Administradores</router-link
              >
            </div>
          </div>
          <div class="col-md-9 mt-4 mt-md-0">
          <hr class="w-100 d-md-none">
            <transition mode="out-in" name="slide-fade">
              <router-view />
            </transition>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import Header from "@/components/Header.vue";
import HeroHeader from "@/components/HeroHeader.vue";

export default {
  title: "Prosci - Painel Administrativo",
  name: "Home",
  components: { HeroHeader, Header },
  data() {
    return {
      header: {
        title: "Painel Administrativo",
        description: "Gerencie clientes, inscrições, documentos e pagamentos.",
        image: "eventos.svg",
      },
      loading: true,
    };
  },
  methods: {},
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 200);
  },
};
</script>

<style lang="scss" scoped>


.payment-status {
  color: white;
  padding: 2rem 2.7rem;
  border-radius: 5px;
}

.documentos {
  background: $secondary;
  color: white;
  cursor: pointer;
}

#v-pills-tab-admin {
  .nav-link {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1rem;
    &:not(.active) {
      background: white;
    }
  }
}
.router-link-exact-active {
  background: $primary !important;
  color: white;
}
</style>