<template>
  <section>
    <div class="hero d-flex align-items-end flex-row">
      <div class="container pb-1">
        <div class="row">
          <div
            class="col d-flex justify-content-start align-items-start flex-column"
          >
            <h3 class="color-text f-600 text-white">{{ data.title }}</h3>
            <p class="text-white f-400 fs-16">
              {{ data.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroHeader",
  props: {
    data: Object,
  },
};
</script>

<style lang="scss" scoped>
.hero {
  height: 200px;
  background-color: $primary;
}
.header-top {
  background: url("../assets/images/bg-eventos.svg") no-repeat center center;
  background-size: cover;
  margin-top: -8rem !important;
  background-color: white;
  border-radius: 5px;
  padding: 1rem;
  min-height: 230px;
  img {
    margin-top: -5rem;
  }
}
@media screen and (max-width: 900px) {
  .header-top {
    margin-top: -5rem !important;
  }
}
</style>