<template>
  <header class="header">
    <div class="container">
      <div class="row">
        <div
          class="col-md-3 col-9 d-flex align-items-center justify-content-start"
        >
          <router-link to="/">
            <img src="../assets/images/logo.svg" alt="Logo" class="img-fluid" />
          </router-link>
        </div>
        <div
          class="col-md-6 d-md-flex d-none flex-column justify-content-center"
          id="main-menu"
        >

        </div>
        <div
          class="col-md-3 col-3 d-flex align-items-center justify-content-end"
        >
          <div class="dropdown user" @click="toggle">
            <span class="rounded-initials">
              <p>{{ name }}</p>
            </span>
            <p class="user-name d-none d-md-block pl-2 m-0 f-600">
              {{ $store.state.user.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <OverlayPanel :dismissable="true" ref="op">
      <ul class="list-group sublist">

        <li class="nav-item position-relative">
          <router-link to="/dados-cadastrais" class="nav-link fs-14 color-text">
            <feather
              class="color-tertiary eye is-14 mr-1"
              type="list"
            ></feather>
            Meus Dados</router-link
          >
        </li>
        <li><hr /></li>
        <li class="nav-item position-relative cursor-pointer">
          <span to="/" class="nav-link fs-14 color-text" @click="logout">
            <feather
              class="text-danger eye is-14 mr-1 "
              type="log-out"
            ></feather>
            Sair</span
          >
        </li>
      </ul>
    </OverlayPanel>
  </header>
</template>

<script>
import OverlayPanel from "primevue/overlaypanel";
export default {
  name: "Header",
  components: { OverlayPanel },
  computed: {
    name() {
      var initials = this.$store.state.user.name.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || "") + (initials.pop() || "")
      ).toUpperCase();
      return initials;
    },
  },
  methods: {
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  height: 90px;
  display: flex;
  align-items: center;
  .justify-content-evenly {
    justify-content: space-evenly !important;
  }
}

#main-menu {
  .router-link-exact-active {
    &::after {
      position: absolute;
      bottom: -1.7rem;
      right: 0;
      content: "";
      height: 5px;
      width: 100%;
      background: $tertiary;
      color: $tertiary;
    }
  }
}
.user {
      border-left: 1px solid #c5c5c5;
    padding-left: 20px;
  cursor: pointer;
  .rounded-initials {
    width: 60px;
    height: 60px;
    background: $secondary;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin: 0;
      padding: 0;
      font-size: 1.2rem;
      color: white;
    }
  }
  display: flex;
  align-items: center;
  color: $text;
}

.sublist {
  list-style: none;
}
</style>
